import { motion } from "framer-motion";
import React from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import styled from "styled-components";

import { FaWhatsapp,
  FaLinkedin,
  FaGithub,
  FaBehanceSquare,
  FaInstagram 
} from "react-icons/fa";

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  /* margin: 5rem auto; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;


  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  position: relative;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-family: "Kaushan Script";
    font-size: ${(props) => props.theme.fontxxl};
    margin-bottom: 1em;
    @media (max-width: 48em) {
      font-size: ${(props) => props.theme.fontxl};
    }
`;

const FooterComponent = styled(motion.footer)`
  width: 80vw;

  @media (max-width: 48em) {
    width: 90vw;
  }
  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem;
    margin-top: 4rem;
    padding: 0 1rem;
    border-top: 1px solid ${(props) => props.theme.text};
    border-bottom: 1px solid ${(props) => props.theme.text};

    @media (max-width: 48em) {
      justify-content: center;
    }
  }

  li {
    padding: 2rem;
    font-size: ${(props) => props.theme.fontlg};
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: 48em) {
      padding: 1rem;
      font-size: ${(props) => props.theme.fontmd};
    }
  }
`;

const Bottom = styled.div`
  padding: 0.5rem 0;
  font-size: ${(props) => props.theme.fontlg};

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: underline;
  }

  @media (max-width: 64em) {
    flex-direction: column;
    justify-content: center;
    span {
      transform: none !important;
    }
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Footer = () => {
  const { scroll } = useLocomotiveScroll();

  const handleScroll = (id) => {
    let elem = document.querySelector(id);
    // console.log(elem);
    scroll.scrollTo(elem, {
      offset: "-100",
      duration: "2000",
      easing: [0.25, 0.0, 0.35, 1.0],
    });
  };

  return (
    <Section>
      <LogoContainer>

        <motion.h3 data-scroll data-scroll-speed="-1"
          initial={{ y:100 ,opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          You will find me here
        </motion.h3>
        <div className="flex gap-4 mt-[1em] sm:gap-[3em] md:gap-[6em]">
          <motion.a href="https://www.linkedin.com/in/abdallah-samy-072486260/" target="_blank" className="text-3xl sm:!text-4xl md:text-6xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.9, delay: 1 }}
          ><FaLinkedin /></motion.a>
          <motion.a href="https://github.com/AbdalllahSamy" target="_blank" className="text-3xl sm:!text-4xl md:text-6xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.9, delay: 1.3 }}
          ><FaGithub /></motion.a>
          <motion.a href="https://www.behance.net/abdalllahsamy" target="_blank" className="text-3xl sm:!text-4xl md:text-6xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.9, delay: 1.6 }}
          ><FaBehanceSquare /></motion.a>
          <motion.a href="https://wa.me/+201020697117" target="_blank" className="text-3xl sm:!text-4xl md:text-6xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.9, delay: 1.9 }}
          ><FaWhatsapp /></motion.a>
          <motion.a href="https://www.instagram.com/llearn2earn/" target="_blank" className="text-3xl sm:!text-4xl md:text-6xl"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.9, delay: 2.2 }}
          ><FaInstagram /></motion.a>
        </div>
      </LogoContainer>
      <FooterComponent
        initial={{ y: "-400px" }}
        whileInView={{ y: 0 }}
        viewport={{ once: false }}
        transition={{
          duration: 1.5,
        }}
      >
        <ul>
          <li aria-hidden="true" onClick={() => handleScroll("#home")}>
            home
          </li>
          <li aria-hidden="true" onClick={() => handleScroll(".about")}>
            about
          </li>
          <li aria-hidden="true" onClick={() => handleScroll("#shop")}>
            Projects
          </li>
          <li aria-hidden="true" onClick={() => handleScroll(".experience")}>
            Experience
          </li>
          <li aria-hidden="true" onClick={() => handleScroll(".skills")}>
            Skills
          </li>
          <li aria-hidden="true" onClick={() => handleScroll(".learn2earn")}>
            L2E
          </li>
        </ul>
        <Bottom>
          <span
            data-scroll
            data-scroll-speed="-2"
            data-scroll-direction="horizontal"
          >
            Coded By Abdallah
          </span>
        </Bottom>
      </FooterComponent>
    </Section>
  );
};

export default Footer;
