import "locomotive-scroll/dist/locomotive-scroll.css";

import { AnimatePresence } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { ThemeProvider } from "styled-components";

// import Loader from "./components/Loader";
import ScrollTriggerProxy from "./components/ScrollTriggerProxy";
import About from "./sections/About";
import Footer from "./sections/Footer";
import Home from "./sections/Home";
import Tools from "./sections/Tools";
import NewArrival from "./sections/NewArrival";
import Shop from "./sections/Shop";
import Experience from "./sections/Experience";
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import './index.css';

function App() {
  // useLocoScroll();
  const containerRef = useRef(null);
  // const [Loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoaded(false);
  //   }, 0);
  // }, []);

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={dark}>
        <LocomotiveScrollProvider
          options={{
            smooth: true,
            // ... all available Locomotive Scroll instance options
            smartphone: {
              smooth: true,
            },
            tablet: {
              smooth: true,
            },
          }}
          watch={
            [
              //..all the dependencies you want to watch to update the scroll.
              //  Basicaly, you would want to watch page/location changes
              //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
            ]
          }
          containerRef={containerRef}
        >
          {/* <AnimatePresence>{Loaded ? null : <Loader />}</AnimatePresence> */}
          <main className="App" data-scroll-container ref={containerRef}>
            <ScrollTriggerProxy />
            <AnimatePresence>
              {/* {Loaded ? null : ""} */}

              <Home key="home" />
              <About key="about" />
              <Shop key="Shop" />
              <div
                className="bg-experience bg-cover bg-center bg-no-repeat 
            rounded-tl-[150px] rounded-br-[150px]"
            key="experience">
                <div
                  className="bg-experienceLight bg-cover bg-center 
            bg-no-repeat rounded-tl-[150px] rounded-br-[130px]"
            key="experience">
                  <Experience key="skills" />
                </div>
              </div>
              <Tools key="tools" />
              <NewArrival key="new arrival" />
              <Footer key="Footer" />
            </AnimatePresence>
          </main>
        </LocomotiveScrollProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
