import React from 'react'
import { Tooltip } from 'react-tooltip'

function Skill({ children, toolName, postion, anchorSelect }) {
    return (
        <div className="relative" >
            <Tooltip
                anchorSelect={"." + anchorSelect}
                place={postion}
                className="absolute top-0 left-0 transform !text-[0.5em] !p-2"
            >
                {toolName}
            </Tooltip>
            {children}
        </div>
    )
}

export default Skill