import React from "react";
import "../animations/animate.css";
import AnimatedBody from "../animations/AnimatedBody.tsx";
import AnimatedTitle from "../animations/AnimatedTitle.tsx";
import {
    SiAdobeaftereffects,
    SiAdobephotoshop, SiFigma, SiAdobexd, SiFramer, SiGit,
    SiGithub,
    SiJavascript,
    SiAdobeillustrator, SiReact,
    SiTailwindcss, SiJquery, SiRedux, SiSocketdotio, SiMysql, SiPusher, SiFirebase, SiOracle, SiComposer, SiBisecthosting
} from "react-icons/si";
import { IoLogoCss3, IoLogoNpm, IoLogoHtml5, IoLogoSass } from "react-icons/io";
import { FaBootstrap, FaPhp, FaLaravel, } from "react-icons/fa";
import { DiMaterializecss } from "react-icons/di";
import AnimatedTools from "../animations/AnimatedTools.tsx";
import Skill from "./Skill.jsx";
const Tools = () => {
    return (
        <section
            className="relative z-10 w-full items-center justify-center overflow-hidden bg-[#1C1C1C] bg-cover bg-center pt-16 pb-36 md:pt-20 md:pb-44 lg:pt-20 lg:pb-56 skills"
            id="tools"
        >
            <div className="mx-auto flex w-[90%] flex-col items-center justify-center lg:max-w-[1212.8px]">
                <AnimatedTitle
                    text={"TOOLS IM USING."}
                    className={
                        "mb-10 text-left text-[40px] font-bold leading-[0.9em] tracking-tighter text-[#e4ded7] sm:text-[45px] md:mb-16 md:text-[60px] lg:text-[80px]"
                    }
                    wordSpace={"mr-[14px]"}
                    charSpace={"mr-[0.001em]"}
                />

                <div className="mx-auto w-[100%] lg:max-w-[1200px] justify-center">
                    <div className="mb-10 flex w-[100%] flex-col gap-4 text-[18px] font-bold leading-relaxed tracking-wide text-[#e4ded7] md:mb-16 md:gap-6 md:text-[40px] md:leading-relaxed lg:mb-16 lg:w-[50%]">
                        <AnimatedBody delay={0.2} text="Frontend" />
                        <div>
                            <AnimatedTools className="grid grid-cols-5 gap-4" delay={0.2} stepSize={0.1} iconSize={50}>
                                <Skill postion="top" toolName="HTML" anchorSelect="html-element">
                                    <IoLogoHtml5 size={50} className="html-element" />
                                </Skill>
                                <Skill postion="top" toolName="CSS" anchorSelect="css-element">
                                    <IoLogoCss3 size={50} className="css-element" />
                                </Skill>
                                <Skill postion="top" toolName="Bootstrap" anchorSelect="bootstrap-element">
                                    <FaBootstrap size={50} className="bootstrap-element" />
                                </Skill>
                                <Skill postion="top" toolName="JavaScript" anchorSelect="js-element">
                                    <SiJavascript size={50} className="js-element" />
                                </Skill>
                                <Skill postion="top" toolName="Sass" anchorSelect="sass-element">
                                    <IoLogoSass size={50} className="sass-element" />
                                </Skill>
                                <Skill postion="top" toolName="Tailwind" anchorSelect="tailwind-element">
                                    <SiTailwindcss size={50} className="tailwind-element" />
                                </Skill>
                                <Skill postion="top" toolName="jQuery" anchorSelect="jquery-element">
                                    <SiJquery size={50} className="jquery-element" />
                                </Skill>
                                <Skill postion="top" toolName="React" anchorSelect="react-element">
                                    <SiReact size={50} className="react-element" />
                                </Skill>
                                <Skill postion="top" toolName="Redux" anchorSelect="redux-element">
                                    <SiRedux size={50} className="redux-element" />
                                </Skill>
                                <Skill postion="top" toolName="Framer Motion" anchorSelect="framer-element">
                                    <SiFramer size={50} className="framer-element" />
                                </Skill>
                                <Skill postion="top" toolName="Firebase" anchorSelect="firebase-element">
                                    <SiFirebase size={50} className="firebase-element" />
                                </Skill>
                                <Skill postion="top" toolName="Materialize" anchorSelect="materialize-element">
                                    <DiMaterializecss size={50} className="materialize-element" />
                                </Skill>
                            </AnimatedTools>
                        </div>
                    </div>
                    <div className="mb-10 flex w-[100%] flex-col gap-4 text-[18px] font-bold leading-relaxed tracking-wide text-[#e4ded7] md:mb-16 md:gap-6 md:text-[40px] md:leading-relaxed lg:mb-16 lg:w-[50%]">
                        <AnimatedBody delay={0.3} text="Backend" />
                        <div>
                            <AnimatedTools className="grid grid-cols-5 gap-4" delay={0.3} stepSize={0.1} iconSize={50}>
                                <Skill postion="top" toolName="PHP" anchorSelect="php-element">
                                    <FaPhp size={50} className="php-element" />
                                </Skill>
                                <Skill postion="top" toolName="MySQL" anchorSelect="mySql-element">
                                    <SiMysql size={50} className="mySql-element" />
                                </Skill>
                                <Skill postion="top" toolName="Laravel" anchorSelect="laravel-element">
                                    <FaLaravel size={50} className="laravel-element" />
                                </Skill>
                                <Skill postion="top" toolName="Pusher" anchorSelect="pusher-element">
                                    <SiPusher size={50} className="pusher-element" />
                                </Skill>
                                <Skill postion="top" toolName="Socket.io" anchorSelect="socket-element">
                                    <SiSocketdotio size={50} className="socket-element" />
                                </Skill>
                                <Skill postion="top" toolName="Oracle Database" anchorSelect="oracle-element">
                                    <SiOracle size={50} className="oracle-element" />
                                </Skill>
                            </AnimatedTools>
                        </div>
                    </div>
                    <div className="mb-10 flex w-[100%] flex-col gap-4 text-[18px] font-bold leading-relaxed tracking-wide text-[#e4ded7] md:mb-16 md:gap-6 md:text-[40px] md:leading-relaxed lg:mb-16 lg:w-[50%]">
                        <AnimatedBody delay={0.1} text="Design" />
                        <div>
                            <AnimatedTools className="grid grid-cols-5 gap-4" delay={0.1} stepSize={0.1} iconSize={50}>
                                <Skill postion="top" toolName="PhotoShop" anchorSelect="photoShop-element">
                                    <SiAdobephotoshop size={50} className="photoShop-element" />
                                </Skill>
                                <Skill postion="top" toolName="After Effect" anchorSelect="aftereffect-element">
                                    <SiAdobeaftereffects size={50} className="aftereffect-element" />
                                </Skill>
                                <Skill postion="top" toolName="Illustrator" anchorSelect="illustrator-element">
                                    <SiAdobeillustrator size={50} className="illustrator-element" />
                                </Skill>
                                <Skill postion="top" toolName="Adobe XD" anchorSelect="xd-element">
                                    <SiAdobexd size={50} className="xd-element" />
                                </Skill>
                                <Skill postion="top" toolName="Figma" anchorSelect="figma-element">
                                    <SiFigma size={50} className="figma-element" />
                                </Skill>
                            </AnimatedTools>
                        </div>
                    </div>
                    <div className="mb-10 flex w-[100%] flex-col gap-4 text-[18px] font-bold leading-relaxed tracking-wide text-[#e4ded7] md:mb-16 md:gap-6 md:text-[40px] md:leading-relaxed lg:mb-16 lg:w-[50%]">
                        <AnimatedBody delay={0.4} text="Other" />
                        <div>
                            <AnimatedTools className="grid grid-cols-5 gap-4" delay={0.4} stepSize={0.1} iconSize={50}>
                                <Skill postion="top" toolName="Github" anchorSelect="github-element">
                                    <SiGithub size={50} className="github-element" />
                                </Skill>
                                <Skill postion="top" toolName="Git" anchorSelect="git-element">
                                    <SiGit size={50} className="git-element" />
                                </Skill>
                                <Skill postion="top" toolName="Composer" anchorSelect="composer-element">
                                    <SiComposer size={50} className="composer-element" />
                                </Skill>
                                <Skill postion="top" toolName="NPM" anchorSelect="npm-element">
                                    <IoLogoNpm size={50} className="npm-element" />
                                </Skill>
                                <Skill postion="top" toolName="Web Hosting" anchorSelect="webHosting-element">
                                    <SiBisecthosting size={50} className="webHosting-element" />
                                </Skill>
                            </AnimatedTools>
                        </div>
                    </div>
                </div>




            </div>
        </section>
    );
};

export default Tools;
