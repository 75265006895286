import {
  ux,
  backend,
} from '../assets';

const experiences = [
  {
    title: 'ًWeb Developer',
    company_name: 'Heights Egypt Expo',
    icon: backend,
    iconBg: '#333333',
    date: 'Mar 2023 - Present',
  },
  {
    title: 'Laravel Developer',
    company_name: 'RStar',
    icon: backend,
    iconBg: '#333333',
    date: 'Dec 2023 - Mar 2023',
  },
  {
    title: 'Laravel Developer (Intern)',
    company_name: 'MagdSoft',
    icon: backend,
    iconBg: '#333333',
    date: 'Aug 2023 - Nov 2023',
  },
  {
    title: 'Graphic Designer',
    company_name: 'YalaFinish',
    icon: ux,
    iconBg: '#333333',
    date: 'May 2022 - Nov 2023',
  },
  {
    title: 'Graphic Designer',
    company_name: 'Heights Egypt Expo',
    icon: ux,
    iconBg: '#333333',
    date: 'Dec 2021 - Present',
  },
];


export { experiences };
